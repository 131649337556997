import { useEffect, useRef } from "react"

export interface EventListenerOptions {
  capture?: boolean,
  passive?: boolean,
  once?: boolean,
}

export type EventHandler<T extends Event> = (e: T) => void

const eventOptions = (): EventListenerOptions => {
  return {}
}

const useEventListener = <T extends Event>(
  event: string,
  handler: EventHandler<T>,
  element: HTMLElement | Window | any,
  options = eventOptions()
): void => {
  // Destructure options
  const { capture, passive, once } = options

  // Create a ref that stores handler
  const savedHandler = useRef<EventHandler<T>>((e: T) => { return })

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
      const elem = element ?? {}

      // Make sure element supports addEventListener
      const isSupported = elem && elem?.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = (e: T) => savedHandler.current(e)

      // Add event listener
      elem?.addEventListener(event, eventListener, { capture, passive, once })

      // Remove event listener on cleanup
      return () => {
        elem?.removeEventListener(event, eventListener, { capture, passive, once })
      }
    },
    [event, element, capture, passive, once] // Re-run if event or element changes
  )
};

export default useEventListener
export { useEventListener }