import { useEffect, useRef } from "react"

const useWindow = (dotAccess?: string): Record<string, never> | Window => {
  const object = useRef({})

  useEffect(() => {
    let currentObject = window

    if(typeof dotAccess === `string`) {
      dotAccess.split(`.`).forEach(attribute => {
        currentObject = currentObject[attribute]
      })
    }

    object.current = currentObject
  }, [])

  return object.current
}

export { useWindow }