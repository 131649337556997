import React, { useEffect, useRef, useState } from "react"
import { useMotionValueEvent, useSpring } from "framer-motion"
import shuffle from "knuth-shuffle-seeded"
import logo from "../images/animation/icon4.png"

const importAll = (r) => r.keys().map(r)
const images = importAll(
    require.context(
      '../images/animation',
      false,
      /\.(png|jpe?g|svg)$/)
  ).map((image: { default?: string }) => image?.default)
   .filter((image: string | undefined) => image)

export const AnimatedMarks = ({
  animate,
  pathname,
  previousPathname,
}: {
  animate: boolean
  pathname: string
  previousPathname?: string
}) => {
  const [shuffledImages, setShuffledImages] = useState([logo, ...images]),
    imgContainerClass = `absolute inset-0 flex items-center justify-center`,
    imgClass = `w-full h-full object-contain opacity-0`,
    latestRef = useRef(0),
    previousModuloRef = useRef(0),
    styleRef = useRef<HTMLStyleElement>(null)

  useEffect(() => {
    setShuffledImages([logo, ...shuffle(images)])
  }, [])

  const activeIndex = useSpring(0, {
    // https://www.framer.com/motion/transition/#spring
    // duration: 8,
    // bounce determines the "bounciness" of a spring animation. 0 is no bounce, and 1 is extremely bouncy. If duration is set, this defaults to 0.25. Note: bounce and duration will be overridden if stiffness, damping or mass are set.
    // bounce: 0,
    // Stiffness of the spring. Higher values will create more sudden movement. Set to 100 by default.
    stiffness: 10,
    // Strength of opposing force. If set to 0, spring will oscillate indefinitely. Set to 10 by default.
    damping: 10,
    // Mass of the moving object. Higher values will result in more lethargic movement. Set to 1 by default.
    mass: 1,
    // The initial velocity of the spring. By default this is the current velocity of the component.
    velocity: 5,
    // End animation if absolute speed (in units per second) drops below this value and delta is smaller than restDelta. Set to 0.01 by default.
    // restSpeed: 30,
    // End animation if distance is below this value and speed is below restSpeed. When animation ends, spring gets “snapped” to. Set to 0.01 by default.
    // restDelta: 0.25,
  })

  useMotionValueEvent(activeIndex, `change`, (latest) => {
    const modulo = Math.round(latest) % shuffledImages.length
    if(modulo !== previousModuloRef.current) {
      // console.log(latest, modulo)
      if(styleRef.current) styleRef.current.innerHTML = `:root { --image-${modulo}-opacity: 1; }`
      latestRef.current = latest
      previousModuloRef.current = modulo
    }
  })

  useEffect(() => {
    // console.log(animate, pathname, previousPathname, pathname !== previousPathname)
    if(!animate || pathname === previousPathname) return
    // console.log(latestRef.current, Math.round(shuffledImages.length / 2), latestRef.current + Math.round(shuffledImages.length / 2))
    activeIndex.set(latestRef.current + Math.round(shuffledImages.length / 2))
  }, [pathname, previousPathname])

  return (
    <div className="relative w-9 h-9">
      <style dangerouslySetInnerHTML={{ __html: shuffledImages.map((image, i, a) =>
          `[data-image="${i}"] > img { opacity: var(--image-${i}-opacity, 0) !important; }`
        ).join(`
`)
      }} />
      <style ref={styleRef}>{`:root { --image-0-opacity: 1; }`}</style>
      {shuffledImages.map((image, i, a) => {
        return (
          <div
            className={imgContainerClass}
            data-image={i}
            key={`${i}-${image}`}
          >
            <img
              className={imgClass}
              src={image}
            />
          </div>
        )
      })}
    </div>
  )
}