import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { primaryInput } from "detect-it"
import { SEO } from "./seo"
import { Header } from "./header"
import { Footer } from "./footer"
import { PageProps } from "gatsby"
import useEventListener from "../lib/useEventListener"
import { AnimatePresence, LazyMotion, m } from "framer-motion"
import usePrevious from "../lib/usePrevious"

const loadMotionFeatures = () =>
  import("../utilities/motionFeatures").then((res) => res.default)

type LayoutProps = {
  children: React.ReactNode
  pageContext: Record<string, unknown>
}

type Theme = "warm-gray" | "marigold" | "black" | "white"

const Layout = ({ children, location, pageContext }: LayoutProps & PageProps) => {
  const defaultStyle = ((pageContext?.background as `string` | undefined)?.toLowerCase().replace(` `, `-`) ?? `warm-gray`) as Theme,
    headerIntersectionObserverRef = useRef<IntersectionObserver>(),
    footerIntersectionObserverRef = useRef<IntersectionObserver>(),
    initializeIntersectionObservers = () => {
      // console.log(`Initialize intersection observers`)
      const header = document.querySelector(`header[data-style]`),
        footer = document.querySelector(`footer[data-style]`),
        clientWidth = document.documentElement.clientWidth,
        clientHeight = document.documentElement.clientHeight

      headerIntersectionObserverRef.current = new IntersectionObserver(
        (entries, observer) => {
          const intersections = entries.filter(e => e.isIntersecting),
            currentHeaderStyle = header?.getAttribute(`data-style`) ?? defaultStyle
          let newHeaderStyle = currentHeaderStyle

          if(intersections.length > 0) newHeaderStyle = intersections[0].target.getAttribute(`data-style`) as Theme
          if(newHeaderStyle !== currentHeaderStyle) {
            // console.log({ currentHeaderStyle, newHeaderStyle })
            header?.setAttribute(`data-style`, newHeaderStyle)
          }
        }, {
          // Desktop logo center: 43.5px
          // Mobile header height: 114.85px
          rootMargin: clientWidth >= 768
            ? `-43px 0px -${clientHeight - 44}px 0px`
            : `-113px 0px -${clientHeight - 114}px 0px`
      })

      footerIntersectionObserverRef.current = new IntersectionObserver(
        (entries, observer) => {
          const intersections = entries.filter(e => e.isIntersecting)

          if(intersections.length === 0) return

          const currentFooterStyle = footer?.getAttribute(`data-style`) ?? defaultStyle,
            newFooterStyle = intersections[0].target.getAttribute(`data-style`) as Theme

          if(newFooterStyle !== currentFooterStyle) {
            // console.log({ currentFooterStyle, newFooterStyle })
            footer?.setAttribute(`data-style`, newFooterStyle)
          }
        }, {
          // Desktop footer center: 27.5px
          // Mobile footer center: 18.75px
          rootMargin: clientWidth >= 768
            ? `-${clientHeight - 28}px 0px -27px 0px`
            : `-${clientHeight - 19}px 0px -18px 0px`
      })
    },
    disconnectIntersectionObservers = () => {
      // console.log(`Disconnect intersection observers`)
      headerIntersectionObserverRef.current?.disconnect()
      footerIntersectionObserverRef.current?.disconnect()
    },
    observeIntersectionObservers = () => {
      // console.log(`Observe new targets`, { pathname: location.pathname, pageContext })
      const intersectionTargetsRoot = document.querySelector(`[data-intersection-targets-root]`),
        intersectionTargets = Array.from(intersectionTargetsRoot?.children ?? [])
  
      intersectionTargets.forEach(target => {
        headerIntersectionObserverRef.current?.observe(target)
        footerIntersectionObserverRef.current?.observe(target)
      })
    },
    previousPathname = usePrevious(location.pathname)

  // Initialize and disconnect intersection observers on mount and unmount
  useEffect(() => {
    initializeIntersectionObservers()
    observeIntersectionObservers()
    return disconnectIntersectionObservers
  }, [])

  // Disconnect and re-initialize intersection observers on resize
  useEventListener(`resize`, (e) => {
    disconnectIntersectionObservers()
    initializeIntersectionObservers()
    observeIntersectionObservers()
  }, typeof window !== `undefined` ? window : {}, { passive: true })

  // Disconnect and observe new slices whenever path changes
  useEffect(() => {
    // Don’t run on mount (previous pathname will be undefined)
    if(!previousPathname) return
    // console.log(`pathname hook`, previousPathname)
    disconnectIntersectionObservers()
  }, [location.pathname])

  // Retrieve page metadata
  const metadata = pageContext?.metadata?.primary ?? {},
    {
      title,
      description,
      image
    } = metadata

  return (
    <div className="flex flex-col h-auto min-h-screen">
      <Helmet>
        <html className={`antialiased ${primaryInput}`} data-style={defaultStyle} />
      </Helmet>
      <SEO
        title={title}
        // Use template for all content but the homepage, unless no title is present
        useTitleTemplate={location.pathname !== `/` && title}
        description={description}
        ogImage={image?.url}
        pathname={location.pathname}
      />
      <LazyMotion features={loadMotionFeatures} strict>
        <Header defaultStyle={defaultStyle} primaryInput={primaryInput} />
        <AnimatePresence
          initial={false}
          mode="wait"
          onExitComplete={() => {
            if(document?.scrollingElement) document.scrollingElement.scrollTop = 0

            // console.log(`Disconnect observers and observe new targets due to path change: ${location.pathname}`)
            // disconnectIntersectionObservers()

            setTimeout(() => {
              observeIntersectionObservers()
            }, 100)

          }}
        >
          <m.div
            initial={{
              position: `fixed`,
              opacity: 0,
            }}
            animate={{
              position: `fixed`,
              opacity: 1,
              transition: {
                duration: 2 / 6
              },
              transitionEnd: {
                position: `static`,
              }
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 1 / 6
              }
            }}
            key={location.pathname}
          >
            {children}
          </m.div>
        </AnimatePresence>
      </LazyMotion>
      <Footer defaultStyle={defaultStyle} />
    </div>
  )
}

export default Layout
export { Layout }
