/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

// You can delete this file if you're not using it

import * as React from "react"
import * as prismic from "@prismicio/client"
import fetch from "node-fetch"
import { GatsbySSR, Link } from "gatsby"
import { PrismicProvider } from "@prismicio/react"
import {
  componentResolverFromMap,
  PrismicPreviewProvider,
} from "gatsby-plugin-prismic-previews"
import { Layout } from "./src/components/layout"
// import { Page } from "./src/components/page"
import { linkResolver } from "./src/utilities/linkResolver"

export const wrapPageElement: GatsbySSR["wrapPageElement"] = ({
  element,
  props,
}) => <Layout {...props}>{element}</Layout>

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => {
  const endpoint = prismic.getEndpoint(process.env.GATSBY_PRISMIC_REPOSITORY ?? ``),
    client = prismic.createClient(endpoint, { fetch })

  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
          linkResolver,
          componentResolver: componentResolverFromMap({
            // page: Page
            // component_type: ComponentTemplate,
          }),
        },
      ]}
    >
      <PrismicProvider
        client={client}
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
        linkResolver={linkResolver}
        richTextComponents={{
          heading1: ({ children }) => <h1 className="font-serif text-[50px] leading-[1.15] tracking-[0.015em] sm:text-3xl" data-rte>{children}</h1>,
          heading2: ({ children }) => <h2 className="font-serif text-[26.5px] leading-[1.15] tracking-[0.015em] text-2xl" data-rte>{children}</h2>,
          heading3: ({ children }) => <h3 className="font-serif text-xl" data-rte>{children}</h3>,
          heading4: ({ children }) => <h4 className="font-sans text-lg" data-rte>{children}</h4>,
          heading5: ({ children }) => <h5 className="font-sans text-base" data-rte>{children}</h5>,
          paragraph: ({ children }) => <p className="font-serif text-base" data-rte>{children}</p>,
          heading6: ({ children }) => <h6 className="font-sans text-sm" data-rte>{children}</h6>,
        }}
      >
        {element}
      </PrismicProvider>
    </PrismicPreviewProvider>
  )
}