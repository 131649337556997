import { useEffect, useRef, useState } from "react"
import { useEventListener } from "./useEventListener"
import { useWindow } from "./useWindow"

const useHideOnScroll = ({ cache = undefined, defaultVisibility = true, hideChange = 100, revealChange = 0, scrollTarget = null } : {
  cache: any,
  defaultVisibility: boolean,
  scrollTarget: HTMLElement | null,
  hideChange: number,
  revealChange: number,
}) => {
  const [hidden, setHidden] = useState(!defaultVisibility),
    window = useWindow(),
    scrollRef = useRef<HTMLElement | null>(scrollTarget),
    scrollTop = useRef(
      scrollRef.current
        ? scrollRef.current?.scrollTop ?? 0
        : 0
    ),
    lastCache = useRef(cache),
    lastHidden = useRef(
      scrollRef.current
        ? scrollRef.current?.scrollTop ?? 0
        : 0
    ),
    scrollHandler = () => {        
      const newScrollTop = document?.scrollingElement?.scrollTop

      if(typeof newScrollTop !== `number`) return
    
      const scrollChange = newScrollTop - (scrollTop.current ?? 0)
      
      // Update last pin reference whenever scollChange is < revealChange
      if (scrollChange < revealChange) lastHidden.current = newScrollTop
    
      // If newScrollTop is > hideChange from last pin reference, hide the navigation
      if (newScrollTop - lastHidden.current > hideChange && !hidden) setHidden(true)
    
      // If scrollChange is < revealChange, reveal the navigation
      if (scrollChange < revealChange && hidden) setHidden(false)

      // Store newScrollTop
      scrollTop.current = newScrollTop ?? scrollTop.current
    },
    reset = () => {
      const newScrollTop = document?.scrollingElement?.scrollTop ?? 0
      // console.log(document?.scrollingElement?.scrollTop, newScrollTop)
      setHidden(!defaultVisibility)
      scrollTop.current = newScrollTop
      lastHidden.current = newScrollTop
    }
    
    useEffect(() => {
      if(cache !== lastCache.current) {
        // console.log(`Reset useHideOnScroll due to cache change`, { cache, "lastCache.current": lastCache.current })
        lastCache.current = cache
        reset()
    }
  }, [cache])
  
  useEventListener(`scroll`, scrollHandler, window, { passive: true })

  return hidden
}

export { useHideOnScroll }