import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

const menuQuery = graphql`
  query Menu {
    prismicSettings {
      _previewable
      data {
        main_menu {
          document {
            ... on PrismicMenu {
              ...MenuFragment
            }
          }
        }
        main_menu_mobile {
          document {
            ... on PrismicMenu {
              ...MenuFragment
            }
          }
        }
      }
    }
  }
`

const useMenu = () => {
  const staticData = useStaticQuery<Queries.MenuQuery>(menuQuery),
    { data, isPreview } = useMergePrismicPreviewData(staticData),
    menu = data?.prismicSettings?.data?.main_menu?.document,
    mobile_menu = data?.prismicSettings?.data?.main_menu_mobile?.document

  return { menu, mobile_menu, isPreview }
}

export { useMenu }