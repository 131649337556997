/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/css/index.css"

export { wrapPageElement, wrapRootElement } from "./gatsby-ssr"

export const shouldUpdateScroll = () => false