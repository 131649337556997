module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["undefined"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5ZM2dFQkJBQUFDY0F3QnZf.77-977-9Zu-_ve-_ve-_ve-_ve-_vU3vv71XOO-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_vUDvv73vv71O77-9ce-_vXvvv718Tw","imageImgixParams":{"auto":"format","dpr":1,"fit":"max","q":50},"imagePlaceholderImgixParams":{"w":2,"q":2},"lang":"*","repositoryName":"marigoldclub","promptForAccessToken":true,"apiEndpoint":"https://marigoldclub.cdn.prismic.io/api/v2","pageSize":100,"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Full Name","short_name":"Name","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"94d8c421a29cda0347091b5d26ec2a73"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
