import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

const settingsQuery = graphql`
  query Settings {
    prismicSettings {
      _previewable
      data {
        # Metadata
        title
        description
        url
        image {
          url
          open_graph: url(imgixParams: { w: 1200, h: 630, fit: "crop" })
        }
        # Contact
        name
        email
        phone
        reservations_link {
          url
        }
        twitter
        facebook
        instagram
        # Address
        map_link {
          url
        }
        street_address
        address_locality
        address_region
        address_region_full
        postal_code
        address_country
      }
    }
  }
`

export const usePrismicSettings = () => {
  const staticData = useStaticQuery<Queries.SettingsQuery>(settingsQuery),
    { data, isPreview } = useMergePrismicPreviewData(staticData)

  return { data, isPreview }
}