import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "@reach/router"
import { FilledLinkToDocumentField } from "@prismicio/types"
import { Logomark, WordmarkHorizontal } from "./svg"
import { useMenu } from "../lib/useMenu"
import { linkResolver } from "../utilities/linkResolver"
import { useHideOnScroll } from "../lib/useHideOnScroll"
import { useWindow } from "../lib/useWindow"
import { AnimatePresence, LayoutGroup, m } from "framer-motion"
import { usePrevious } from "../lib/usePrevious"
import { AnimatedMarks } from "./AnimatedMarks"
import useEventListener from "../lib/useEventListener"

type NonEmptyObj<T extends Record<string, unknown>> = T extends Record<string, never> ? never : T;

type NonNullablePrismicSettings = NonNullable<Queries.MenuQuery["prismicSettings"]>
type NonNullableMainMenu = NonNullable<NonNullablePrismicSettings["data"]["main_menu"]>
type NonNullableMainMenuDocument = NonEmptyObj<NonNullable<NonNullableMainMenu["document"]>>
type LinkType = NonNullable<NonNullableMainMenuDocument["data"]["links"]>[number]
// type NonNullableSubmenuDocument = NonEmptyObj<NonNullable<NonNullable<NonNullable<LinkType>["submenu"]>["document"]>>

const linkHasSubmenu = (link: LinkType) => {
  return link?.submenu?.document && `data` in link.submenu.document
}

const NavLink = ({
  anyLinkPathActive,
  hoverIndex,
  i,
  length,
  level = 0,
  link,
  pathname,
  previousHoverIndex,
  primaryInput,
  setHoverIndex
}: {
  anyLinkPathActive: boolean,
  hoverIndex: number | undefined,
  i: number,
  length: number,
  level?: number,
  link: LinkType
  pathname: string,
  previousHoverIndex: number | undefined,
  primaryInput?: `mouse` | `touch`,
  setHoverIndex: React.Dispatch<React.SetStateAction<number | undefined>>
}) => {
  const to = (
    link?.link?.link_type === `Document`
      ? linkResolver(link.link as unknown as FilledLinkToDocumentField<string, string, never>)
      : link?.link?.url
    ) ?? `/`,
  active = to === pathname,
  hasDot = hoverIndex === i || (
    hoverIndex === undefined
    && active
  ),
  hash = to.indexOf(`https://#`) === 0 && to.replace(`https://`, ``)

  const linkContent = (
    <>
      {link?.text ?? ``}
      {level === 0 && (
        <m.div
          className={`absolute -top-[1.5px] md:-top-[2.5px] bottom-full left-2 ${i === 0 ? `md:left-[-2.5px]` : i < length - 1 ? `` : `md:left-[calc(100%-2.5px)]`} w-[5px] h-[5px] rounded-full bg-current`}
          key={hasDot ? `${anyLinkPathActive}` : undefined}
          layout="position"
          layoutId={hasDot ? `nav-hover-dot` : undefined}
          initial={{
            opacity: hasDot
              ? anyLinkPathActive || previousHoverIndex !== undefined 
                ? 1 : 0
              : !anyLinkPathActive && hoverIndex === undefined && previousHoverIndex === i
                ? 1 : 0,
          }}
          animate={{
            opacity: hasDot ? 1 : 0,
          }}
          exit={{
            opacity: hasDot
              && hoverIndex === undefined
              && to === pathname
                ? 1 : 0,
          }}
          transition={{
            default: { duration: 0.3 },
            layout: { type: `spring`, bounce: 0.075 } 
          }}
        />
      )}
    </>
  ),
  linkProps = {
    className: `relative whitespace-nowrap px-2.5 py-3 md:px-3 ${i === 0 ? `md:pl-0` : i < length - 1 ? `` : `md:pr-0`} ${level !== 0 ? `-mt-[1.5px] md:border-t md:border-black` : ``}`,
    key: i,
    onMouseEnter: level === 0 && primaryInput === `mouse` ? () => setHoverIndex(i) : undefined,
    onFocus: level === 0 ? () => setHoverIndex(i) : undefined,
    onMouseLeave: level === 0 && primaryInput === `mouse` ? () => setHoverIndex(undefined) : undefined,
    onBlur: level === 0 ? () => setHoverIndex(undefined) : undefined,
    ...(
      link?.link?.link_type === `Web` && !hash
        ? { rel: `noopener noreferrer`, target: `_blank` }
        : {}
    ),
    ...(
      hash
        ? {
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            document.querySelector(hash)?.scrollIntoView({ behavior: `smooth` })
          }
        }
        : {}
    )
  }

  return link?.link?.link_type === `Document`
    ? <Link to={to} {...linkProps}>{linkContent}</Link>
    : hash
      ? <a href={hash} {...linkProps}>{linkContent}</a>
      : <a href={to} {...linkProps}>{linkContent}</a>
}

const Header = ({
  defaultStyle,
  primaryInput,
}: {
  defaultStyle: `warm-gray` | `marigold` | `black` | `white`
  primaryInput?: `mouse` | `touch`
}) => {
  const headerRef = useRef<HTMLElement>(null),
    { menu, mobile_menu } = useMenu(),
    links = menu && `data` in menu && menu?.data?.links,
    mobileLinks = mobile_menu && `data` in mobile_menu && mobile_menu?.data?.links
      ? mobile_menu?.data?.links : links,
    firstLink = links && links.length > 0 && links[0],
    lastLink = links && links.length > 1 && links[links.length - 1],
    window = useWindow(),
    scrollingElement = useWindow(`document.scrollingElement`),
    { pathname } = useLocation(),
    [loaded, setLoaded] = useState(false),
    previousPathname = usePrevious(pathname),
    hiddenOnScroll = useHideOnScroll({
      cache: pathname,
      defaultVisibility: true,
      scrollTarget: scrollingElement as unknown as HTMLElement,
      hideChange: 100,
      revealChange: 0,
    }),
    [hasFocusVisibleWithin, setHasFocusVisibleWithin] = useState(false),
    hidden = hiddenOnScroll && !hasFocusVisibleWithin,
    [hoverIndex, setHoverIndex] = useState<number | undefined>(undefined),
    previousHoverIndex = usePrevious(hoverIndex),
    allLinkPaths = (links || []).map((link, i) => (
      link?.link?.link_type === `Document`
        ? linkResolver(link.link as unknown as FilledLinkToDocumentField<string, string, never>)
        : link?.link?.url
      ) ?? `/`
    ),
    activeLinkPathIndex = allLinkPaths.indexOf(pathname),
    anyLinkPathActive = activeLinkPathIndex > -1,
    activeLink = (links || [])[activeLinkPathIndex],
    activeSubmenu = linkHasSubmenu(activeLink),
    allMobileLinkPaths = (mobileLinks || []).map((link, i) => (
      link?.link?.link_type === `Document`
        ? linkResolver(link.link as unknown as FilledLinkToDocumentField<string, string, never>)
        : link?.link?.url
      ) ?? `/`
    ),
    activeMobileLinkPathIndex = allMobileLinkPaths.indexOf(pathname),
    anyMobileLinkPathActive = activeMobileLinkPathIndex > -1,
    activeMobileLink = (mobileLinks || [])[activeMobileLinkPathIndex],
    activeMobileSubmenu = linkHasSubmenu(activeMobileLink),
    wasActiveMobileSubmenu = usePrevious(activeMobileSubmenu)

  useEffect(() => {
    setLoaded(true)
  }, [])

  useEventListener(`focusin`, () => {
    headerRef.current?.matches(`:has(:focus-visible)`) && setHasFocusVisibleWithin(true)
  }, window)

  useEventListener(`focusout`, () => {
    setTimeout(() => {
      !headerRef.current?.matches(`:has(:focus-visible)`) && setHasFocusVisibleWithin(false)
    }, 0)
  }, window)

  const staticLogomarks = (
    <div className="flex justify-center py-8 md:py-6">
      {/* Logomark / wordmark */}
      <Link className="p-2 -m-2" to="/">
        {/* Desktop logomark */}
        <Logomark className="hidden md:block w-9 fill-current">
          <title>The Marigold Club</title>
        </Logomark>
        {/* Mobile wordmark */}
        <WordmarkHorizontal className="md:hidden w-48 fill-current">
          <title>The Marigold Club</title>
        </WordmarkHorizontal>
      </Link>
    </div>
  )

  return (
    <header
      className="pointer-events-none h-max max-h-[var(--header-height-mobile)] md:max-h-[var(--header-height-desktop)] overflow-hidden z-10 fixed top-0 inset-x-0 md:px-20 font-serif italic text-[13px] md:text-[15px] leading-[1.2] tracking-[0.11em] bg-transparent"
      data-style={defaultStyle}
      ref={headerRef}
    >
      <style>{`
        :root {
          --header-height-desktop: ${activeSubmenu ? `164px` : `127px`};
          --header-height-mobile: ${activeMobileSubmenu ? `175px` : `138px`};
        }
      `}</style>
      <m.div
        className="pointer-events-auto data-style md:data-unstyle"
        animate={{ opacity: hidden ? 0 : 1 }}
        key={pathname}
      >
        <div className="flex justify-center py-8 md:py-6">
          <Link className="p-2 -m-2" to="/">
            <AnimatedMarks
              animate={loaded}
              pathname={pathname}
              previousPathname={previousPathname}
            />
          </Link>
        </div>
        {/* {staticLogomarks} */}
        {/* Desktop border */}
        <div className="h-px bg-current" />
        {/* Desktop links */}
        <div className="relative h-auto hidden md:flex justify-between items-stretch">
          <LayoutGroup id="desktop-nav">
            {firstLink ? (
              <NavLink
                anyLinkPathActive={anyLinkPathActive}
                hoverIndex={hoverIndex}
                i={0}
                length={links.length}
                link={firstLink}
                pathname={pathname}
                previousHoverIndex={previousHoverIndex}
                primaryInput={primaryInput}
                setHoverIndex={setHoverIndex}
              />
            ) : null}
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-flex items-stretch">
              {links && links.map((link, i, a) => {
                if(i === 0 || i === a.length - 1) return null
                return (
                  <NavLink
                    anyLinkPathActive={anyLinkPathActive}
                    hoverIndex={hoverIndex}
                    i={i}
                    key={i}
                    length={links.length}
                    link={link}
                    pathname={pathname}
                    previousHoverIndex={previousHoverIndex}
                    primaryInput={primaryInput}
                    setHoverIndex={setHoverIndex}
                  />
                )
              })}
            </div>
            {lastLink ? (
              <NavLink
                anyLinkPathActive={anyLinkPathActive}
                hoverIndex={hoverIndex}
                i={links.length - 1}
                length={links.length}
                link={lastLink}
                pathname={pathname}
                previousHoverIndex={previousHoverIndex}
                primaryInput={primaryInput}
                setHoverIndex={setHoverIndex}
              />
            ) : null}
          </LayoutGroup>
        </div>
        {/* Mobile links */}
        <div className="relative h-9 flex md:hidden justify-between items-stretch">
          <LayoutGroup id="mobile-nav">
            <AnimatePresence>
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-flex items-stretch">
                {mobileLinks && mobileLinks.map((link, i, a) => {
                  return (
                    <NavLink
                      anyLinkPathActive={anyMobileLinkPathActive}
                      hoverIndex={hoverIndex}
                      i={i}
                      key={i}
                      length={mobileLinks.length}
                      link={link}
                      pathname={pathname}
                      previousHoverIndex={previousHoverIndex}
                      primaryInput={primaryInput}
                      setHoverIndex={setHoverIndex}
                    />
                  )
                })}
              </div>
            </AnimatePresence>
          </LayoutGroup>
        </div>
        {/* Mobile border */}
        <div className="md:hidden h-px bg-current" />
        {/* Secondary navigation */}
        <div className="relative w-full h-9">
          {links && links.map((link, i, a) => {
            const submenu = link?.submenu?.document && `data` in link.submenu.document
              ? link.submenu.document
              : undefined,
              to = (
                link?.link?.link_type === `Document`
                  ? linkResolver(link.link as unknown as FilledLinkToDocumentField<string, string, never>)
                  : link?.link?.url
                ) ?? `/`,
              active = to === pathname,
              wasActive = to === previousPathname

            if(!submenu) return null

            return (
              <m.div
                className={`${active ? `pointer-events-auto` : `pointer-events-none`} absolute inset-0 flex justify-center`}
                {...{
                  inert: !active ? `` : undefined,
                }}
                initial={{ opacity: wasActive ? 1 : 0 }}
                animate={{ opacity: active ? 1 : 0 }}
                exit={{ opacity: active ? 1 : 0 }}
                transition={{
                  duration: 0.3,
                  ease: `easeInOut`,
                }}
                key={`${pathname}-submenu-${i}`}
              >
                {submenu.data.links?.map((sublink, i, a) => {
                  return (
                    <NavLink
                      anyLinkPathActive={anyLinkPathActive}
                      hoverIndex={i}
                      i={i}
                      key={i}
                      length={a.length}
                      level={1}
                      link={sublink as LinkType}
                      previousHoverIndex={previousHoverIndex}
                      pathname={pathname}
                      setHoverIndex={setHoverIndex}
                    />
                  )
                })}
              </m.div>
            )
          })}
        </div>
        {/* Secondary navigation mobile border */}
        <m.div
          className="md:hidden h-px bg-current"
          initial={{ opacity: wasActiveMobileSubmenu ? 1 : 0 }}
          animate={{ opacity: activeMobileSubmenu ? 1 : 0 }}
          exit={{ opacity: activeMobileSubmenu ? 1 : 0 }}
          transition={{
            duration: 0.3,
            ease: `easeInOut`,
          }}
        />
      </m.div>
    </header>
  )
}

export { Header }